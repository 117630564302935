import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import _ from "underscore";
import classNames from 'classnames';

import CleanerUtils from '@services/cleaner_utils';
import PriceTag from '@components/common/price_tag'

class CleanerBox extends React.Component {
    static propTypes = {
        cleaner: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
            locked_name: PropTypes.string,
            image_path: PropTypes.string,
            description_with_locale: PropTypes.object, //Should be in the form locale: String
            rating: PropTypes.string,
            visitsDone: PropTypes.number,
            foreignLanguages: PropTypes.arrayOf(PropTypes.string),
            deaf: PropTypes.bool,
            companyId: PropTypes.number,
            initialFeedbacks: PropTypes.arrayOf(PropTypes.shape({
                author: PropTypes.string,
                rating: PropTypes.number,
                maxRating: PropTypes.number,
                content: PropTypes.string,
                submitted_at: PropTypes.string
            })),
            allFeedbacksShowed:  PropTypes.bool,
            soleContractor:  PropTypes.bool,
            payWithCard:  PropTypes.bool,
            previous_choice: PropTypes.bool,
            promoted: PropTypes.bool,
            greenCertificate: PropTypes.bool,
            supportsFix: PropTypes.bool,
            youtubeVideoId: PropTypes.string,

            ranking: PropTypes.shape({
                domestina_rank: PropTypes.number,
                price: PropTypes.string,
            }),
        }),

        onSelect: PropTypes.func,
        selectBtnText: PropTypes.string,
        profileSelectBtnText: PropTypes.string,

        showRecommendedBadge: PropTypes.bool,
        showPromotedProfile: PropTypes.bool,

        // the current top rank which will determine recommended choice badge
        collectionTopRank: PropTypes.number,

        isCurrentAddressMember: PropTypes.bool,
        buyAddressMembership: PropTypes.bool,

        openCleanerProfileDialog: PropTypes.func,
    };

    handleSelect = (e) => {
        e.stopPropagation();
        this.props.onSelect(this.props.cleaner.id);
    };

    handleProfileSelect = () => {
        this.props.onSelect(this.props.cleaner.id);
    };


    visitPrice = () => {
        return parseFloat(this.props.cleaner.ranking.price);
    }

    isMembershipDiscountActive = () => {
        return this.props.buyAddressMembership || this.props.isCurrentAddressMember;
    }


    hasRating = () => {
        return this.props.cleaner.rating !== null && this.props.cleaner.rating > 0;
    };

    isOnlyCash = () => {
       return !this.props.cleaner.payWithCard;
    }

    isOnlyCard = () => {
        return this.props.cleaner.payWithCard;
    }

    renderPreviousChoiceBadge = () => {
        return (
            <div className='previous-choice-badge info-badge'>
                <span className="glyphicon glyphicon-time" aria-hidden="true"/>
                {i18n.t('funnel.companies.badges.previous-choice')}
            </div>
        );
    };

    renderVideoPresentationBadge = () => {
        return (
            <div className='video-presentation-badge info-badge' onClick={this.openMoreInfoModal}>
                <span className="glyphicon glyphicon-play" aria-hidden="true" title={i18n.t('funnel.companies.badges.video-presentation')}/>
                {i18n.t('funnel.companies.badges.video')}
            </div>
        );
    }

    renderPaymentBadge = () => {
        return (
            <div className='payment-badge info-badge'>
                <div className={classNames({'card-icon': this.isOnlyCard(), 'cash-icon': this.isOnlyCash(), }) + " payment-icon"} aria-hidden="true"/>
                {this.isOnlyCard() && i18n.t('funnel.companies.badges.card-payment')}
                {this.isOnlyCash() && i18n.t('funnel.companies.badges.cash-payment')}
            </div>
        );
    };

    renderGreenCertificate = () => {
        return (
            <div className='green-certificate info-badge'>
                {this.renderGreenCertificateOverlay()}
            </div>
        );
    };


    renderGreenCertificateOverlay = () => {
        var tooltip = (
            <Tooltip id={'green-certificate-badge-' + this.props.cleaner.id}>
                {i18n.t('funnel.companies.badges.green-certificate-tooltip')}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <span>
                    <div className={"qr-icon payment-icon"} aria-hidden="true"/>
                    {i18n.t('funnel.companies.badges.green-certificate')}
                </span>
            </OverlayTrigger>
        );
    };

    renderNewCleanerLabel = () => {
        return (
            <div className="badges-container">
                <div className="new-cleaner-label">
                    <span>{CleanerUtils.humanCategory('newbie', this.props.cleaner.deaf)}</span>
                </div>
            </div>
        );
    };

    renderCleanerLanguages = () => {
        return(
            <div className="cleaner-languages">
                {this.props.cleaner.foreignLanguages.length > 0 && _.map(this.props.cleaner.foreignLanguages, function(language, i) {
                    var classes = "flag-icon flag-icon-" + language;
                    return (
                        <span className={classes} title={i18n.t("languages." + language)} key={i}></span>
                    );
                })}
            </div>
        );
    };

    renderCleanerDescription = () => {
        return (
            <div className="cleaner-description">
                <p>{this.truncateText(this.cleanerDescriptionWithLocale(this.props.cleaner))}</p>
            </div>
        );
    };


    truncateText = (text) => {
        var length = 150;
        var ending = '...';
        if (typeof text === "undefined") {
            return i18n.t('funnel.companies.no-description');
        }

        if (text.length > length) {
            return text.replace(/<[^>]*>?/gm, '').substring(0, length - ending.length) + ending;
        } else {
            return text;
        }
    };

    cleanerDescriptionWithLocale = (cleaner) => {
        if(cleaner.description_with_locale)
            if(cleaner.description_with_locale[i18n.locale])
                return cleaner.description_with_locale[i18n.locale];
            else
                return cleaner.description_with_locale[i18n.defaultLocale];
        else
            return "N/A";
    }

    visiblePromoted = () => {
        return this.props.showPromotedProfile && this.props.cleaner.promoted;
    };

    visibleRecommendation = () => {
        return this.props.showRecommendedBadge && (this.props.cleaner.ranking.domestina_rank === this.props.collectionTopRank) && !this.visiblePromoted();
    };

    cleanerName = () => {
        return this.props.isCurrentAddressMember ? this.props.cleaner.name : this.props.cleaner.locked_name
    }

    openMoreInfoModal = (e) => {
        e.preventDefault();
        this.props.openCleanerProfileDialog(this.props.cleaner.id);
    }

    renderFullProfileTrigger = () => {
        return (
            <a className="view-full-profile" href="#" onClick={this.openMoreInfoModal}>
                {i18n.t('funnel.cleaners-options.all-cleaners.show-full-profile')}
            </a>
        );
    };

    renderButton = () => {
        return (
            <button className="btn btn-primary select-button" onClick={_.partial(this.props.onSelect, this.props.cleaner.id)}> {this.props.selectBtnText} </button>
        )
    }


    render() {
        return (
            <div className={'modern-cleaner-preview'}>
                <div className="cleaner-photo-container">
                    <div className={"selector-cleaner-photo"} style={{'backgroundImage': 'url(' + this.props.cleaner.image_path + ')'}}>
                        {this.props.cleaner.deaf && <div className="deaf-symbol"><span className="deaf-img-white"></span></div>}
                    </div>
                </div>
                <div className="info-container">
                    <div className="cleaner-name" onClick={this.openMoreInfoModal}>
                        {this.cleanerName()}&nbsp;
                        {this.props.cleaner.favorite &&
                            <span className={"text-danger glyphicon glyphicon-heart"} style={{'fontSize': '20px'}} aria-hidden="true"></span>
                        }
                    </div>
                    {this.renderCleanerLanguages()}
                    <div className='info-badges-container'>
                        {this.props.cleaner.youtubeVideoId && this.renderVideoPresentationBadge()}
                        {this.props.cleaner.previous_choice && this.renderPreviousChoiceBadge()}
                        {this.renderPaymentBadge()}
                        <div className="clearer"></div>
                    </div>
                    {this.renderCleanerDescription()}
                    <div className="view-full-profile-trigger">
                        {this.renderFullProfileTrigger()}
                    </div>
                </div>
                <div className="rating-cta-container">
                    <div className="rating-and-price-container">
                        {this.props.cleaner.rating && <div className="rating-visits-container">
                            <div className="rating-container">
                                <span className="glyphicon glyphicon-star" aria-hidden="true"> </span> {this.props.cleaner.rating} / {10}
                            </div>
                            <a href="#" onClick={this.openMoreInfoModal}>
                                {i18n.t('funnel.companies.out-of-visits', {events_count: this.props.cleaner.visitsDone})}
                            </a>
                        </div>}
                        {!this.props.cleaner.rating && this.renderNewCleanerLabel()}

                        <div className="price-container">
                            <PriceTag amountWithCurrency={this.visitPrice().toString()} className="price-tag"/>
                            <br/>
                            {i18n.t('funnel.cleaners-options.per-hours', { hours: this.props.cleaner.atomicSlotDuration.replace(/\.0$/, "") })}
                        </div>
                    </div>
                    <div className="cta-container">
                        {this.renderButton()}
                    </div>
                </div>
            </div>
        )
    }
}

export default CleanerBox;
