import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _ from 'underscore';
import FeedbacksList from '@components/common/feedbacks_list';
import CleanerPhoto from '@components/common/cleaner_photo';
import CleanerUtils from '@services/cleaner_utils';


class CleanerProfile extends React.Component {
    static propTypes = {
        cleaner: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
            locked_name: PropTypes.string,
            image_path: PropTypes.string,
            description: PropTypes.string,
            rating: PropTypes.string,
            visitsDone: PropTypes.number,
            foreignLanguages: PropTypes.arrayOf(PropTypes.string),
            deaf: PropTypes.bool,
            companyId: PropTypes.number,
            initialFeedbacks: PropTypes.arrayOf(PropTypes.shape({
                author: PropTypes.string,
                rating: PropTypes.number,
                maxRating: PropTypes.number,
                content: PropTypes.string,
                submitted_at: PropTypes.string
            })),
            allFeedbacksShowed:  PropTypes.bool,
            soleContractor:  PropTypes.bool,
            payWithCard:  PropTypes.bool,
            previous_choice: PropTypes.bool,
            promoted: PropTypes.bool,
            recent_no_show_count: PropTypes.number,
            recent_no_call_cancellations_count: PropTypes.number,
            recent_excess_cancellations_count: PropTypes.number,
            favorite: PropTypes.bool,
            youtubeVideoId: PropTypes.string,

            requestPath: PropTypes.string,
            requestParams: PropTypes.object,

            ranking: PropTypes.shape({
                domestina_rank: PropTypes.number,
                price: PropTypes.string,
            }),
        }),
        isCurrentAddressMember: PropTypes.bool,
        onAddToFavoritesClicked: PropTypes.func,
        isLoggedin: PropTypes.bool,
    };

    cleanerName = () => {
        return this.props.isCurrentAddressMember ? this.props.cleaner.name : this.props.cleaner.locked_name
    }

    hasPenalty = () => {
        return this.props.cleaner.recent_no_show_count > 0 ||
            this.props.cleaner.recent_no_call_cancellations_count > 0 ||
            this.props.cleaner.recent_excess_cancellations_count > 0;
    }

    heartClass = () => {
        return 'glyphicon-heart';

        // if(this.props.cleaner.favorite)
        //     return 'glyphicon-heart';
        // else
        //     return 'glyphicon-heart-empty';
    }

    addToFavoritesText = () =>{
        if(this.props.cleaner.favorite)
            return i18n.t('cleaner-profile.added-to-favorites');
        else
            return i18n.t('cleaner-profile.add-fav');
    }

    cleanerDescriptionWithLocale = () => {
        if(this.props.cleaner.description_with_locale)
            if(this.props.cleaner.description_with_locale[i18n.locale])
                return this.props.cleaner.description_with_locale[i18n.locale];
            else
                return this.props.cleaner.description_with_locale[i18n.defaultLocale];
        else
            return "N/A";
    }

    render() {
        return (
            <div className="cleaner-profile">
                <div className="base-container">
                    <CleanerPhoto image_path={this.props.cleaner.image_path} deaf={this.props.cleaner.deaf}/>

                    <div className="cleaner-name-and-category offset-by-photo hidden-xs">
                        <div className="cleaner-name">{this.cleanerName()}</div>
                    </div>
                </div>

                <div className="overlay-container">
                    <div className="cleaner-name-and-category visible-xs-block">
                        <div className="cleaner-name">{this.cleanerName()}</div>
                    </div>

                    <div className="cleaner-stats offset-by-photo">
                        { this.props.cleaner.rating &&
                        <div className="cleaner-stats-item">
                            <div className="value">
                                {this.props.cleaner.rating}/{10}
                            </div>
                            <div className="name">
                                {i18n.t('cleaner-profile.rating')}
                            </div>
                        </div>}
                        { this.props.cleaner.rating &&
                        <div className="cleaner-stats-item">
                            <div className="value">
                                {this.props.cleaner.visitsDone}
                            </div>
                            <div className="name">
                                {i18n.t('cleaner-profile.visits')}
                            </div>
                        </div>}

                        { !this.props.cleaner.rating &&
                        <div className={'new-contractor-container'}>
                            {i18n.t('cleaner-profile.new-on-the-platform')}
                        </div>}
                    </div>

                    <hr className="separator"/>

                    {this.props.cleaner.deaf &&
                        <div className={"profile-deaf-legend"}>
                            <div className={'deaf-symbol'}></div>
                            {i18n.t("funnel.cleaners-options.all-cleaners.deaf-title")}
                        </div>
                    }

                    <p className="cleaner-description">
                        {this.cleanerDescriptionWithLocale()}
                    </p>

                    {this.props.cleaner.youtubeVideoId &&
                        <div className="contractor-embedded-youtube-container">
                            <iframe src={"https://www.youtube.com/embed/" + this.props.cleaner.youtubeVideoId}>
                            </iframe>
                        </div>
                    }

                    {(this.props.onAddToFavoritesClicked && this.props.isLoggedin) &&
                    <div className="add-to-favorites-container">
                        <button type="button" className={"btn btn-default" + classNames({'btn-gray-bg': this.props.cleaner.favorite})} onClick={this.props.onAddToFavoritesClicked}>
                            <span className={"text-danger glyphicon " + this.heartClass()} aria-hidden="true"></span> &nbsp;
                            {this.addToFavoritesText()}
                        </button>
                    </div>}

                    {(this.props.cleaner.initialFeedbacks.length > 0) &&
                    <h4 className="section-heading">
                        {i18n.t('cleaner-profile.feedbacks')}
                    </h4>}

                    <FeedbacksList initialFeedbacks={this.props.cleaner.initialFeedbacks}
                                   recipientName={this.props.cleaner.name}
                                   allFeedbacksShowed={this.props.cleaner.allFeedbacksShowed}
                                   requestPath={this.props.cleaner.requestPath}
                                   requestParams={this.props.cleaner.requestParams} />
                </div>
            </div>
        );
    }
}

export default CleanerProfile;
